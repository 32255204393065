.hero {

  @include flex(column, center, center);
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../images/background/manicure_background_v4.jpg');
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &_ear {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../images/background/diana_ear_background.jpg');
  }

  &__content {
    width: $mobileWidth;
    background-color: $colorPrimary;
    padding: 1rem 2rem; 
    box-shadow: $boxShadowAccent;
  }

  &__title {
    @include text-shadow($colorBlack);
    color: $colorAccent;
    margin-bottom: 2rem;

    font: {
      size: 2.1rem;
      family: $fontTitle;
      weight: 500;
    }

    text: {
      align: center;
      transform: capitalize;
    }
  }

  &__list {
    @include flex(column, start, start);
    gap: 0.5rem;
    list-style: none;
  }

  &__item {
    width: 100%;
  }

  &__link {
    @include flex(row, space-between, center);
    @extend .text-basic;
    text-decoration: none;
    width: 100%;
    gap: 1rem;
    cursor: pointer;
    transition: $transitionLinear;

    .icon {
      @include flex(row, center, center);
      color: $colorPrimary;
      background-color: $colorAccent;
      border-radius: $borderRadiusRound;
      font-size: 1rem;
      padding: 2px;
      box-shadow: $boxShadow;
    }
  }

 
}

//RESPONSIVE
@include media-xsm {
  .hero {

    &__content {
      width: 300px;
    }
  }
}

@include media-lg {
  .hero {

    &__content {
      width: 400px;
    }
 
    &__title {
      font-size: 3rem;
    }

    &__link {
      &:hover {
        transform: scale(1.1);
        color: $colorAccent;
      }
    }

  }
}