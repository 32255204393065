.features {
  padding: $sectionPaddingSmall;
  margin: 0 auto;

  &__list {
    margin: 0 auto;
    list-style: none;
    @include flex(column, center, center);
    gap: 1rem;
  }

  &__item {
    width: $mobileWidth;
    height: 300px;
    position: relative;
    box-shadow: $boxShadowAccent;
    overflow: hidden;

    &:hover .features__background {
      transform: scale(1.05);
    }
  }

  &__background {
    width: 100%;
    height: 100%;
    transition: transform 0.9s ease-in-out;
    background: {
      image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../../images//portfolio/work71.jpg");
      repeat: no-repeat;
      size: cover;
      position: center;
    };

    &_ear {
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../../images/ear/ear_hero.jpg");
    }
  }

  &__title {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;
    @include text-shadow($colorBlack);
    color: $colorAccent;
    font-family: $fontPrimary;
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include text-shadow($colorAccent);
    color: $colorPrimary;
    font-style: italic;
    text-align: center;
    width: 100%;
    padding: 1rem;
    
    font: {
      size: 1.2rem;
      family: $fontPrimary;
      weight: 500;
    }
  }

  &__icon {
    @include flex(column, center, center);
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 1;
    background-color: $colorAccent;
    border-radius: 50px;
    padding: 1rem;

    .icon {
      color: $colorPrimary;
      font-size: 1.2rem;
    }
  }
}

// RESPONSIVE
@include media-xsm {
  .features {

    &__item {
      width: 300px;
    }
  }
}

@include media-md { 
  .features {
    width: $desktopWidth;  
    padding-top: 6rem;

    &__list {
      flex-direction: row;
      gap: 2rem;
    }

    &__item {
      width: 350px;
      height: 350px;
    }
  }
}

@include media-lg { 
  .features {
    
    &__item {
      width: 400px;
      height: 400px;
    }
  }
}