.testimonials {
  background-color: $colorAccent;
  width: $fullWidth;
  margin: 3rem 0;
  padding: 3rem 0;

  &__title {
    margin-bottom: 3rem;
    @include text-shadow($colorBlack);
    color: $colorPrimary;

    text: {
      transform: uppercase;
      align: center;
    }

    font: {
      family: $fontTitle;
      size: 1.5rem;
      weight: 400;
      style: normal;
    }
  }

  &__list {
    list-style: none;
    width: 80%;
    margin: 0 auto;
  }

  &__item {
    box-shadow: $boxShadowAccent;
    background-color: $colorPrimary;
  }

  &__image-container {
    width: 100%;
    height: 200px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    padding: 1rem;
  }

  &__text {
    font-size: 1rem;
    font-family: $fontPrimary;

    .icon {
      color: $colorAccent;

      &_left {
        margin-right: 5px;
      }

      &_right {
        margin-left: 5px;
      }
    }
  }

  &__name {
    text-align: right;
    color: $colorAccent;
    font-size: 1.1rem;
    font-family: $fontTitle;
    padding-top: 1rem;
  }

}

.slick-slide > div {
  margin: 0 10px;
}

//RESPONSIVE 
@include media-md {
  .testimonials {
    padding: 3rem 0 6rem;

    &__list {
      width: 90%;
    }

    &__title {
      margin-bottom: 6rem;
      font-size: 2rem;
    }
  }
}