.link-button {
  @include flex(column, center, center);
  position: relative;
  box-shadow: $boxShadow;
  background-color: $colorAccent;
  color: $colorPrimary;
  padding: 10px 25px;
  width: 150px;
  height: 40px;
  z-index: 1;
  transition: $transitionLinear;
  cursor: pointer;
  
  text: {
    decoration: none;
    transform: uppercase;
    align: center;
  }
  
  font: {
    family: $fontPrimary;
    weight: 500;
    size: 1rem;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: $colorPrimary;
    transform: scaleX(0);
    transition: all 0.5s ease-in-out;
  }
}

// RESPONSIVE
@include media-md {
  .link-button {
    height: 50px;
    width: 150px;
  }
}

@include media-lg {
  .link-button {
    &:hover::before {
      transform: scaleX(1);
    }
  
    &:hover {
      color: $colorAccent;
      letter-spacing: 2px;
    }
  }
}
