.alert-message {
  
  @include flex(column, center, center);
  gap: 1rem;
  width: $mobileWidth;
  padding: 2rem;
  background-color: $colorPrimary;
  box-shadow: $boxShadow;
  margin: 0 auto;

  &__icon {
    font-size: 5rem;
    color: $colorAccent;
  }

  &__text {
    @extend .text-basic;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

// RESPONSIVE
@include media-md {
  .alert-message {
    width: 300px;
  }
}