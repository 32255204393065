.cards {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    gap: 1rem;

    &__more {
      @extend .link-button-basic;
      margin: 4rem auto 0;
      border: none;
      outline: none;
    }
  }

// RESPONSIVE
@include media-md {
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2rem;
  }
}
