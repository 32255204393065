@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin text-shadow($color) {
  text-shadow: 2px 2px 2px $color;
}

