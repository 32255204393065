.about {
  padding: $sectionPaddingSmall;

  &__content {
    @include flex(column, space-between, center);
    gap: 5rem;
    margin: 0 auto;
    width: $mobileWidth;
  }

  &__image-container {
    position: relative;
  }
  
  &__image {
    object-fit: cover;
    width: $fullWidth;
    height: auto;
    box-shadow: $boxShadowAccent;

    &_small {
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 1;
      width: 180px;
      height: 180px;
      box-shadow: $boxShadowAccent;
    }
  }

  &__text-container {
    @include flex(column, space-between, start);
    gap: 1rem;
  }

  &__text {
    @extend .text-basic;

    &_accent {
      font: {
        family: $fontTitle;
        weight: bold;
        size: 1.2rem;
      }
      color: $colorAccent;
    }
  }

  &__contact-container {
    @include flex(row, start, center);
    gap: 40px;
    margin-top: 2rem;
  }
}

// RESPONSIVE

@include media-sm {
  .about {

    &__image {
  
      &_small {
        width: 250px;
        height: 250px;
      }
    }
  }
}

@include media-md {
  .about {
    padding: $sectionPaddingMedium;

    &__content {
      width: $desktopWidth;
    }
    
    &__text {
      font-size: $fontSizeMd;
    }
  }
}


@include media-lg {
  .about {
      
    &__content {
      @include flex(row, space-between, start);
    }

    &__image-container {
      width: 50%;
    }

    &__text-container {
      width: 50%;
    }
  }
}


@include media-xlg {
  .about {

    &__image {
      width: 400px;

      &_small {
        left: 400px;
        transform: translate(-50%, 0);
        width: 250px;
        height: 250px;
      }
    }
  }
}
  