.socialMedia {
  padding: 3rem 0;

  &__content {
    @include flex(column, center, center);
    width: $fullWidth;
    height: $fullHeight;

    background: {
      image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../../images/background/contactback.jpg');
      repeat: no-repeat;
      position: center;
      size: cover;
    }
  }

  &__info {
    @include flex(column, center, center);
    gap: 1rem;
    background-color: $colorPrimary;
    box-shadow: $boxShadow;
    width: $mobileWidth;
    min-height: 250px;
    padding: 1rem;
  }

  &__text {
    @extend .text-basic;
    @include flex(row, center, center);
    gap: 5px;
    font-size: $fontSizeSm;
  }
}

// RESPONSIVE
@include media-sm {

  .socialMedia {

    &__info {
      width: 300px;
      transition: $transitionLinear;
  
      &:hover {
        transform: scale(1.1);
      }
    }

    &__text {
      font-size: $fontSizeMd;
    }
  }
}
