.hamburger {
  @include flex(column, center, center);
  position: relative;
  z-index: 999;
  cursor: pointer;
  box-shadow: $boxShadow;
  background-color: $colorAccent;
  padding: 5px;
  transition: $transitionLinear;
  width: 40px;
  height: 40px;

  &__content {
    @include flex(column, center, end);
  }
}

.bar {
  width: 2.5rem;
  width: 2rem;
  height: 0.2rem;
  border-radius: 5px;
  background-color: $colorPrimary;
  box-shadow: $boxShadow;
  transition: $transitionLinear;
}

.bar_middle {
  margin: 0.5rem 0;
  width: 2rem;
  width: 1.5rem;
}

.active .bar {
  width: 2rem;
}

.active .bar_top {
  transform: rotate(-45deg) translate(-0.5rem, 0.5rem);
}

.active .bar_middle {
  opacity: 0;
}

.active .bar_bottom {
  transform: rotate(45deg) translate(-0.5rem, -0.5rem);
}

// FIXED NAV
.nav_fixed .hamburger {
  width: 40px;
  height: 40px;
}

// RESPONSIVE
@include media-md {
  .hamburger {
    width: 50px;
    height: 50px;
  }
}

@include media-lg {
  .hamburger {
    &:hover {
      background-color: $colorPrimary;
    }
  }

  .hamburger:hover .bar {
    background-color: $colorAccent;
  }
}