.sectionTitle {
  margin-bottom: 3rem;
  
  &__title {
    @include text-shadow($colorBlack);
    color: $colorAccent;
   
    text: {
      transform: uppercase;
      align: center;
    }
  
    font: {
      family: $fontTitle;
      size: 1.5rem;
      weight: 400;
      style: normal;
    } 
  }
}

//RESPONSIVE 
@include media-md {
  .sectionTitle {
    &__title {
      font-size: 2rem;
    }
  }
}
