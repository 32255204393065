.contact {
  padding: $sectionPaddingSmall;

  &__content {
    @include flex(column, space-between, space-between);
    gap: 2rem;
    width: $mobileWidth;
    margin: 0 auto;
  }

  &__text-container {
    @include flex(column, space-between, space-between);
    gap: 1rem;
    margin-top: 2rem;
  }

  &__text {
    @extend .text-basic;
    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  &__list-container {
    position: relative;
  }

  &__list {
    list-style: none;
    @include flex(column, center, center);
    gap: 1rem;
    box-shadow: $boxShadowAccent;
    padding: 2rem 0 9rem;
    background: {
      image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../../images/background/diana_manicure_background.jpg');
      repeat: no-repeat;
      position: center;
      size: cover;
    }
  }

  &__info {
    @include flex(column, center, center);
    @include text-shadow($colorBlack);
    @extend .text-basic;
    color: $colorPrimary;
    gap: 5px;
    width: $fullWidth;
    text-align: center;

    .icon {
      @include flex(column, center, center);
      background-color: $colorAccent;
      color: $colorPrimary;
      box-shadow: $boxShadow;
      width: 40px;
      height: 40px;
      padding: 5px;
      border-radius: $borderRadiusRound;
    }
  }

  &__social-info {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    @include flex(column, center, center);
    gap: 1rem;
    background-color: $colorPrimary;
    box-shadow: $boxShadowAccent;
    width: 180px;
    min-height: 180px;
    padding: 1rem;
  }

  &__social-text {
    @extend .text-basic;
    @include flex(row, center, center);
    gap: 5px;
    font-size: $fontSizeSm;
  }
}

// RESPONSIVE
@include media-md {
  .contact {
    padding: $sectionPaddingMedium;

    &__content {
      width: $desktopWidth;
    }

    &__text {
      font-size: $fontSizeMd;
    }
  }
}
@include media-lg {
  .contact {

    &__content {
      gap: 3rem;
      @include flex(row, center, start);
    }

    &__text-container {
      width: 50%;
      @include flex(column, start, start);
      order: 1;
      margin-top: 0;
    }

    &__list-container {
      width: 50%;
      order: 2;
    }
  }
}

@include media-xlg {
  .contact {

    &__social-info {
      left: 0px;
      bottom: -120px;
      transform: translate(-50%, 0);
      width: 250px;
      height: 250px;
    }

  }
}
