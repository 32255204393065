.scrollToTop {

  &__button {
    box-shadow: $boxShadow;
    border: none;
    outline: none;
    position: fixed;
    bottom: 3rem;
    right: 0;
    z-index: 10;
    border-radius: 50px 0 0 50px;
    background: $colorAccent;
    color: $colorPrimary;
    width: 50px;
    height: 40px;
    cursor: pointer;

    .icon {
      transition: $transitionLinear;
    }
  }
}

// RESPONSIVE
@include media-lg {
  .scrollToTop {
  
    &__button {
      width: 60px;
      bottom: 6rem;

      .icon {
        font-size: 2rem;
      }

      &:hover .icon {
        transform: translateY(-3px);
      }
    }
  }
}