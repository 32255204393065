$fullWidth: 100%;
$mobileWidth: calc(100% - 2 * 20px);
$desktopWidth: 90%;

$fullHeight: 100vh;

$sectionPaddingSmall: 2rem 0;
$sectionPaddingMedium: 3rem 0;

$fontTitle: "Pacifico";
$fontPrimary: "Inter";

$fontSizeSm: 1rem;
$fontSizeMd: 1.1rem;

$boxShadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px, rgba(0, 0, 0, 0.2) 0px 2px 6px 2px;
 
$boxShadowAccent: #4ea6c8 0px 2px 5px -1px,
#4ea6c8 0px 1px 3px -1px;

$transitionLinear: .3s linear;

$borderRadiusRound: 50px;