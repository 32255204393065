.slideshow {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  transition: .6s ease;
  opacity: 0;

  &.active {
    opacity: 1;
  }
}


// RESPONSIVE
@include media-md {
  .slideshow {
    background-size: 50%;
    background-position: left;
  }
}