.footer {
  background-color: $colorDarkOpacity;
  padding: $sectionPaddingSmall;
  padding-bottom: 0;
  margin-top: 3rem;

  &__content {
    width: $mobileWidth;
    margin: 0 auto;
  }

  &__info {
    padding: 1rem 0;
  }

  &__nav {
    padding: 1rem 0;
    margin-top: 1rem;
    @include flex(column, center, center);
    gap: 3rem;
  }

  &__list {
    @include flex(column, center, center);
    gap: 1.5rem;
    list-style: none;
  }

  &__link, &__contact {
    @include flex(column, center, center);
    gap: 3px;
    color: $colorPrimary;
    text-align: center;
    font: {
      family: $fontPrimary;
      weight: 400;
      size: 15px;
    }

    .icon {
      @include flex(column, center, center);
      background-color: $colorAccent;
      box-shadow: $boxShadow;
      width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: $borderRadiusRound;
    }
  }

  &__link {
    cursor: pointer;
    text-decoration: none;
  }

  &__title {
    color: $colorAccent;
    font: {
      family: $fontPrimary;
      weight: 500;
      size: 1.3rem;
    }
    text-transform: capitalize;
    margin-bottom: 10px;
  } 

  &__copy-container {
    @include flex(column, center, center);
    gap: 5px;
    padding: 2rem 0;
    margin-top: 3rem;
    border-top: 1px solid $colorPrimary;
  }

  &__copy {
    @include flex(row, center, center);
    font: {
      family: $fontPrimary;
      weight: 400;
      size: 15px;
    }
    color: $colorPrimary;
    text-align: center;

    &:last-of-type {
      font-size: 14px;
      color: $colorAccent;
    }
  }
}

// RESPONSIVE
@include media-md {
  .footer {
    padding: $sectionPaddingMedium;
    padding-bottom: 0;
    margin-top: 6rem;

    &__content {
      width: $desktopWidth;
    }

    &__logo {
      @include flex(row, start, start);
    }

    &__nav {
      @include flex(row, space-between, start);
      gap: 5px;
      margin-top: 4rem;
    }

    &__list {
      @include flex(column, space-between, start);
      gap: 1rem;
    }

    &__link,
    &__contact {
      @include flex(row, start, center);
      gap: 10px;
      text-align: left;
      transition: $transitionLinear;
    }
  }
}


@include media-lg {
  .footer {
    &__link {
      &:hover {
        @include text-shadow($colorAccent);
      }
    }
  }
}