.page-not-found {
  @include flex(column, center, center);
  background-color: $colorPrimary;
  width: $fullWidth;
  height: $fullHeight;

  &__content {
    @include flex(column, center, center);
    gap: 1rem;
  }

  &__image {
    object-fit: contain;
    width: 150px;
    height: 150px;
  }

  &__title {
    @extend .text-basic;
  }

  &__link {
    @extend .link-button-basic;
  }
  
}