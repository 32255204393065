/* Small devices (portrait tablets and large phones, 600px and up) */
@mixin media-xsm {
  @media screen and (min-width: 400px) {
    @content;
  }
}

@mixin media-sm {
  @media screen and (min-width: 600px) {
    @content;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@mixin media-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@mixin media-lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@mixin media-xlg {
  @media screen and (min-width: 1200px) {
    @content;
  }
}