.card {
  
  box-shadow: $boxShadowAccent;
  transition: $transitionLinear;
  position: relative;
 // width: 250px;
 // height: 300px;

  &__image {
    object-fit: cover;
    width: $fullWidth;
    height: 100%;
  }

  &__overlay {
    @include flex(column, center, center);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $colorLightOpacity;
    overflow: hidden;
    width: $fullWidth;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: $transitionLinear;
    transition: $transitionLinear;
    cursor: pointer;
  }

  &__icon {
    @include flex(column, center, center);
    background-color: $colorAccent;
    color: $colorPrimary;
    width: 40px;
    height: 40px;
    font-size: 3rem;
  }
}

// RESPONSIVE
@include media-md {
  .card {
    
    &:hover {
      transform: scale(1.1);
      box-shadow: $colorAccent 0px 1px 2px 0px,
      $colorAccent 0px 2px 6px 2px;
    }

    &:hover .card__overlay {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
}
