.popup {
  @include flex(column, center, center);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  width: $fullWidth;
  height: $fullHeight;
  background: $colorDarkOpacity;
  overflow: auto;
  pointer-events: none;
  opacity: 0;
  transition: $transitionLinear;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  &__content {
    @include flex(column, center, center);
    width: $mobileWidth;
    height: 70vh;
    box-shadow: $boxShadow;
    transform: scale(0);
    transition: $transitionLinear;
    position: relative;

    &.active {
      transform: scale(1);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__close-button {
    position: absolute;
    right: -8px;
    top: -50px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    transition: $transitionLinear;

    &:hover .popup__close-icon {
      color: $colorPrimary;
    }
  }

  &__close-icon {
    color: $colorAccent;
    font-size: 3rem;
    transition: $transitionLinear;
  }

}

// RESPONSIVE
@include media-xsm {
  .popup {

    &__content {
      width: 350px;
      height: 80vh;
    }
  }
}

@include media-lg {
  .popup {

    &__content {
      width: 500px;
      height: 80vh;
    }

    &__close-button {
      right: -50px;
      top: -50px;
    }
  }
}
