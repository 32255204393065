.logo {

  &__link {
    @include flex(column, center, center);
    text-decoration: none;
    cursor: pointer;
  }

  &__image {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
}

// FIXED NAV
.nav_fixed .logo__image {
  width: 50px;
  height: 50px;
}

// RESPONSIVE 

@include media-lg {
  .logo {
    transition: $transitionLinear;

    &:hover {
      transform: scale(1.1);
    }

    &__image {
      width: 100px;
      height: 100px;
    }
  }
}
