.intro {
  padding: $sectionPaddingSmall;
  
  &__content {
    width: $mobileWidth;
    margin: 0 auto;
  }

  &__list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    gap: 4rem;
    
  }

  &__item {
    @include flex(column, center, center);
    
  }

  &__image {
    object-fit: cover;
    width: 100px;
    height: 100px;
  }

  &__text {
    font: {
      size: $fontSizeSm;
      family: $fontPrimary;
      weight: 500;
    }
    text: {
      transform: capitalize;
      align: center;
    }
    color: $colorDarkOpacity;
  }
}

// RESPONSIVE

@include media-sm {
  .intro {

    &__list {
      grid-template-columns: repeat(2, auto);
      gap: 50px;
    }
  }
}

@include media-md  {
  .intro {
    padding: $sectionPaddingMedium;

    &__content {
      width: $desktopWidth;
    }
  }
}

@include media-lg  {
  .intro {
    &__list {
      grid-template-columns: repeat(3, auto);
      gap: 2rem;
      justify-content: space-around;
    }
  }
}