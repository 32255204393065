@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {

  @include flex (column, center, center);
  gap: 1rem;
  margin: 0 auto;
  box-shadow: $boxShadow;
  padding: 2rem;
  width: $mobileWidth;
  background-color: $colorPrimary;

  &__icon {
    font-size: 5rem;
    color: $colorAccent;
    animation: rotation 2s infinite;
  }

  &__text {
    @extend .text-basic;
    text-transform: capitalize;
  }
}

// RESPONSIVE
@include media-md {
  .loader {
    width: 300px;
  }
}