.link-button-basic {
  @include flex(column, center, center);
  position: relative;
  box-shadow: $boxShadow;
  background-color: $colorAccent;
  color: $colorPrimary;

  text: {
    decoration: none;
    transform: uppercase;
    align: center;
  }

  font: {
    family: $fontPrimary;
    weight: 500;
    size: 18px;
  }

  padding: 10px 25px;
  width: 180px;
  height: 60px;
  z-index: 1;
  transition: $transitionLinear;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: $colorPrimary;
    transform: scaleX(0);
    transition: all 0.5s ease-in-out;
  }
}

.text-basic {
  font: {
    family: $fontPrimary;
    weight: 500;
    size: $fontSizeSm;
  }

  line-height: 25px;
  color: $colorBlack;
}

@include media-lg {
  .link-button-basic {
    &:hover::before {
      transform: scaleX(1);
    }

    &:hover {
      color: $colorAccent;
      letter-spacing: 2px;
    }
  }
}