.portfolio {
  padding: $sectionPaddingSmall;

  &__content {
    width: $mobileWidth;
    margin: 0 auto;
  }
}

// RESPONSIVE
@include media-md {
  .portfolio {
    padding: $sectionPaddingMedium;

    &__content {
      width: $desktopWidth;
    }
  }
}
