 /*
.treatments {
  padding: $sectionPaddingSmall;

  &__content {
    @include flex(column, start, space-between);
    width: $mobileWidth;
    margin: 3rem auto 0;
  }

  &__image {
    object-fit: cover;
    width: $fullWidth;
    height: 50vh;
    box-shadow: $boxShadowAccent;
  }

  &__list {
    @include flex(column, start, space-between);
    gap: 1rem;
    list-style: none;
    padding: 1rem;
  }

  &__item {
    @include flex(row, space-between, center);
  }

  &__treatment,
  &__price {
    @extend .text-basic;
  }

  &__treatment::first-letter {
    text-transform: uppercase
  }

  &__price {
    @include flex(row, space-between, center);
  }
}

// RESPONSIVE
@include media-sm {
  .treatments {

    &__info {
      @include flex(row, start, space-between);
    }

    &__image {
      width: 50%;
      height: 70vh;

      &_second {
        order: 2;
      }
    }
    
    &__list {
      width: 50%;
      align-self: center;
    }
  }
}

@include media-md {
  .treatments {
    padding: $sectionPaddingMedium;

    &__content {
      width: $desktopWidth;
    }

    &__treatment,
    &__price {
      font-size: $fontSizeMd;
    }
  }
}
*/

.treatments {
  padding: $sectionPaddingSmall;

  &__content {
    width: $mobileWidth;
    margin: 3rem auto 0;
  }

  &__list {
    @include flex(column, start, space-between);
    gap: 1rem;
    list-style: none;
  }

  &__item {
    @include flex(row, space-between, start);
    padding: 5px 10px;
    //box-shadow: $boxShadow;

    &:nth-child(even) {
      background-color: #f9f3ec;
    }
  }

  &__treatment,
  &__price {
    @extend .text-basic;
  }

  &__treatment::first-letter {
    text-transform: uppercase
  }

  &__price {
    @include flex(row, space-between, center);
  }
}

// RESPONSIVE

@include media-md {
  .treatments {
    padding: $sectionPaddingMedium;

    &__content {
      width: 50%;
    }

    &__treatment,
    &__price {
      font-size: $fontSizeMd;
    }
  }
}