.filter-button {
  margin-bottom: 2rem;
 
  &__content {
    @include flex(column, center, center);
    gap: 10px;
    margin: 0 auto;
  }

  &__button {
    @include flex(row, center, center);
    box-shadow: $boxShadow;
    color: $colorPrimary;
    background-color: $colorAccent;
    width: $fullWidth;
    padding: 10px;
    border: none;
    outline: none;
    transition: $transitionLinear;
    cursor: pointer;
    text: {
      decoration: none;
      transform: uppercase;
      align: center;
    }
  
    font: {
      family: $fontPrimary;
      weight: 500;
      size: $fontSizeSm;
    }

    &:hover {
      background-color: $colorPrimary;
      color: $colorAccent;
    }

  }
}

// RESPONSIVE

@include media-xsm {
  .filter-button {

    &__button {
      width: 50%;
    }
  }
}

@include media-sm {
  .filter-button {
    margin-bottom: 3rem;

    &__content {
      @include flex(row, center, center);
    }

    &__button {
      width: 100px;
    }
  }
}