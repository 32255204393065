.home {

  &__content {
    display: flex;
    position: relative;
  }

  &__info {
    @include flex(column, center, center);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    background-color: $colorPrimary;
    box-shadow: $boxShadow;
    width: 300px;
    height: 200px;
  }

  &__title {
    @include text-shadow($colorAccent);
      min-width: $mobileWidth;
      color: $colorBlack;
    
      font: {
        size: 16px;
        family: $fontPrimary;
        weight: 500;
      }
    
      text: {
        align: center;
        transform: uppercase;
      }
  }

  &__background_first {
    height: 100vh;
    width: 100%;
    
    background: {
      image: linear-gradient(rgba(0, 0, 0, 0),  rgba(0, 0, 0, 0)), url("../../../images/portfolio/work1.jpg");
      repeat: no-repeat;
      size: cover;
      position: center;
    };
  }

  &__background_second {
    height: 100vh;
    width: 100%;
    
    background: {
      image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("../../../images/portfolio/work2.jpg");
      repeat: no-repeat;
      size: cover;
      position: center;
    };
  }
}