.social {
 
  &__list {
    @include flex(row, center, center);
    list-style: none;
    gap: 10px;
  }
  
  &__link {
    @include flex(row, center, center);
    padding: 5px;
    width: 40px;
    height: 40px;
    font-size: 25px;
    color: $colorPrimary;
    background-color: $colorAccent;
    background-color: $colorAccent;
    box-shadow: $boxShadow;
    transition: $transitionLinear;
  }
}

// FIXED NAV
.nav_fixed .social__link {
  width: 40px;
  height: 40px;
}


// RESPONSIVE
@include media-md {
  .social {

  &__list {
      gap: 10px;
    }

    &__link {
      width: 50px;
      height: 50px;
    }
  }
}

@include media-lg {
  .social {

    &__link {
      &:hover {
        color: $colorAccent;
        background-color: $colorPrimary;
        border-radius: $borderRadiusRound;
      }
    }
  }
}
