.header {

  &__content {
    @include flex(column, center, center);
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  &__info {
    @include flex(column, center, center);
    position: relative;
    width: $mobileWidth;
    box-shadow: $boxShadow;
    height: 60vh;
    background-color: $colorLightOpacity;
    padding: 1rem;
  }

  &__title {
    @include text-shadow($colorBlack);
    color: $colorAccent;
    margin-bottom: 2rem;
    font: {
      size: 2.1rem;
      family: $fontTitle;
      weight: 500;
    }
    text: {
      align: center;
      transform: capitalize;
    }
  }

  &__subtitle {
    @include text-shadow($colorBlack);
    color: $colorPrimary;
    font: {
      size: 1.5rem;
      family: $fontPrimary;
      weight: 500;
    }
    text: {
      align: center;
    }
  }

  &__link {
    @extend .link-button-basic;
    position: absolute;
    bottom: -30px;
    background-color: $colorAccent;
  }
}

// RESPONSIVE

@include media-md {
  .header {
    background-color: $colorAccent;
    background-image: linear-gradient(to bottom left, $colorAccent, #c3916f, $colorAccent);
    
    &__info {
      position: absolute;
      top: 50%;
      left: 70%;
      transform: translate(-50%, -50%);
      background-color: $colorPrimary;
      padding: 2rem;
      width: 50%;
    }

    &__subtitle {
      @include text-shadow($colorPrimary);
      color: $colorBlack;
      font-size: 1.8rem;
    }
  }
}

@include media-lg {
  .header {

    &__info {
      height: 70vh;
      left: 70%;
    }

    &__title {
      font-size: 3rem;
    }

    &__link {
      font-size: 25px;
      width: 300px;
      height: 70px;
      bottom: -35px;
    }
  }
}