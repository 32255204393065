@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url(inter/Inter-Regular.woff2) format("woff2"),
    url(inter/Inter-Regular.woff) format("woff"),
    url(inter/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url(inter/Inter-Medium.woff2) format("woff2"),
    url(inter/Inter-Medium.woff) format("woff"),
    url(inter/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 900;
  src: url(inter/Inter-Black.woff2) format("woff2"),
    url(inter/Inter-Black.woff) format("woff"),
    url(inter/Inter-Black.ttf) format("truetype");
}
