.certificates {
  padding: $sectionPaddingSmall;

  &__content {
    width: $mobileWidth;
    margin: 0 auto;
  }

  &__list {
    @include flex(column, center, center);
    gap: 1rem;
    list-style: none;
  }

  &__image {
    object-fit: auto;
    width: 100%;
    height: 300px;
    box-shadow: $boxShadowAccent;
    border: 3px solid $colorAccent;
  }
}

// RESPONSIVE

@include media-md {
  .certificates {
    padding: $sectionPaddingMedium;

    &__content {
      width: $desktopWidth;
    }

    &__list {
      flex-direction: row;
      gap: 2rem;
    }
  }
}
