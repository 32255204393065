.videos {
  background-color: $colorPrimary;
  width: $fullWidth;
  padding: 3rem 0;

  &__content {
    margin: 0 auto;
    width: $mobileWidth;
  }
  
  &__list {
    list-style: none;
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }

  &__item {
    box-shadow: $boxShadowAccent;
    cursor: pointer;
    transition: $transitionLinear;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


//RESPONSIVE 

@include media-sm {
  .videos {

    &__list {
      grid-template-columns: repeat(2, auto);
    }
  }
}

@include media-md {
  .videos {

    &__content {
      margin: 0 auto;
      width: $desktopWidth;
    }

    &__item {
      width: 250px;
      height: 300px;
    }
  }
}

@include media-lg {
  .videos {

    &__list {
      grid-template-columns: repeat(3, auto);
      gap: 2rem;
    }

    &__item {

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}