@keyframes fixedTransition {
  from {
    transform: translateY(-100px);
  }

  to {
    transform: translateY(0);
  }
}

.nav {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  z-index: 50;

  &_fixed {
    animation: 2s fixedTransition;
    box-shadow: 2px 2px 4px $colorAccent;
    background: $colorDarkOpacity;

    &.nav {
      padding: 5px 0;
    }
  }

  &__header {
    @include flex(row, space-between, center);
    width: $mobileWidth;
    margin: 0 auto;
  }

  &__hamburger-container {
    @include flex(row, space-between, center);
    gap: 2.5rem;
  }
  
  &__list-container {
    @include flex(column, center, center);
    gap: 2rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    width: $fullWidth;
    height: $fullHeight;
    background-color: $colorDarkOpacity;
    color: $colorPrimary;
    overflow: auto;
    transform: translateX(100%);
    transition: .7s ease-in-out;
  
    &.active {
      transform: translateX(0);
    }
  }

  &__list {
    @include flex(column, center, start);
    gap: 10px;
    list-style: none;
  }

  &__link {
    @include text-shadow($colorBlack);
    @include flex(row, center, center);
    gap: 10px;
    color: $colorPrimary;
    transition: $transitionLinear;
    cursor: pointer;
    text: {
      decoration: none;
      transform: uppercase;
    }
    font: {
      family: $fontPrimary;
      weight: 500;
      size: 18px;
    }

    &_active {
      color: $colorAccent;
      text-shadow: none;
    }
  }

  &__icon {
    @include flex(column, center, center);
    background-color: $colorAccent;
    color: $colorPrimary;
    box-shadow: $boxShadow;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: $borderRadiusRound;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: $fullWidth;
    height: $fullHeight;
    background-color: $colorLightOpacity;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
}

// RESPONSIVE

@include media-md {
  .nav {

    padding: 5px 0;

    &__header {
      @include flex(row, space-between, center);
      width: 90%;
    }

    &__list-container {
      width: 50%;
    }
  }
}

@include media-lg {
  .nav {

    &__link {

      &:hover {
        color: $colorAccent;
        transform: scale(1.2);
      }
    }
  }
}
